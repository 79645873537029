import Vue from "vue";

const firebaseConfig = {
  apiKey: "AIzaSyDYmZkYZw7RQR_0h2G17ow6CaXS2AgNl78",
  authDomain: "kibo-company.firebaseapp.com",
  databaseURL: "https://kibo-company-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "kibo-company",
  storageBucket: "kibo-company.appspot.com",
  messagingSenderId: "479410463224",
  appId: "1:479410463224:web:26d3874df1bf12db5cb60d",
  measurementId: "G-SD7Y123DR0"
};

Vue.mixin({
  data: function () {
    return {
      get companyName() {
        return "Kibo Company"; //公司名稱
      },
      get regFormDomain() {
        return "https://kiboco.xyz/"; 
      },
    };
  },
});

//  溫馨提示：建議使用較淺的顏色
//  歡迎回來的背景顏色
const panelColor        = "#e8f2fa"

//  溫馨提示：建議使用較深的顏色
//  側邊欄的背景顏色
const sideBarColor      = "#000"

//        /||\
//       / || \
//      |------|
//      |  ||  |
//      |  ||  |
//      |  ||  |
//      |  ||  |
//       \ || /
//  不用理 \||/
//        /||\
//       / || \
export {
    firebaseConfig,
    panelColor,
    sideBarColor
}